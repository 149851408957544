import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DriversService {

  apiurl='https://api.buy2go.us/api';
  driverId;
  token = localStorage.getItem('a_token')
  headeroption = {
    headers : new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    })
  };

  new_driverId: BehaviorSubject<number>;


  constructor(private _HttpClient:HttpClient) {
    this.new_driverId = new BehaviorSubject(this.driverId);

   }

  // get all drivers
  getdrivers()
  {
    return this._HttpClient.get(`${this.apiurl}/drivers`,this.headeroption)

  }

   // get product info 
   getdriverinfo()
   {
     return this._HttpClient.get(`${this.apiurl}/driver/info`,this.headeroption)
   }

  //get driver by id
  getDriver(id)
  {
    return this._HttpClient.get(`${this.apiurl}/drivers/${id}`,this.headeroption)

  }

   //get driver by id
   getdriver(id)
   {
     return this._HttpClient.get(`${this.apiurl}/users/${id}`,this.headeroption)
 
   }

   // add new driver
   add(data)
   {
    return this._HttpClient.post(`${this.apiurl}/users`,data,this.headeroption)
     
   }

  // get delete driver
  delete(id)
  {
    return this._HttpClient.delete(`${this.apiurl}/users/${id}`,this.headeroption)

    
  }

  // update driver
  update(data,id)
  {
    return this._HttpClient.post(`${this.apiurl}/users/${id}`,data,this.headeroption)
  }

  // set driver id
  setdriverid(id)
  {
    this.driverId = id;
    this.new_driverId.next(this.driverId);

  }

  //get role
  getallrole()
  {
    return this._HttpClient.get(`${this.apiurl}/roles`,this.headeroption)
  }
}
