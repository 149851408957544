import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginAdminComponent } from './login-admin/login-admin/login-admin.component';
import { LoggGuard } from './shared/auth/logg.guard';

const appRoutes: Routes = [

  // my route
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
     path: 'login', 
     loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
     //canActivate: [LoggGuard] 
  },

  { path: 'admin', 
  component: FullLayoutComponent, 
  data: { title: 'full Views' }, 
  children: Full_ROUTES, 
  //canActivate: [AuthGuard] 
  },


  { path: 'admin', 
  component: ContentLayoutComponent, 
  data: { title: 'content Views' }, 
  children: CONTENT_ROUTES, 
  //canActivate: [AuthGuard] 
},

  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
