import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import {OrdersService} from '../services/orders.service'
// import { toString } from "@ng-bootstrap/ng-bootstrap/util/util";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleIcon', {static: false} ) toggleIcon: ElementRef;
  @ViewChild('logosidbar' , { static : false}) logosidbar : ElementRef
  public menuItems: any[];
  depth: number;
  count: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo3.png';
  public config: any = {};
  role:boolean = false;


  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private _OrdersService : OrdersService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }


  ngOnInit() {
    // this.count = this.config.layout.sidebar.count;
    console.log("hh" , this.count)
    if(this.count == null || this.count == undefined)
    {
      this.count = 0;
    }

    if(localStorage.getItem('role') == 'admin')
    {
      this.role = true;
    }
    this.config = this.configService.templateConf;
    this.menuItems = ROUTES;
   // console.log("hh" , this.count)


    // this._OrdersService.ordercount().subscribe(
    //   res=>{
    //     console.log("res" , res['data']['count']);
    //     this.count = res['data']['count'];
    //     console.log("hh" , this.count)

    //     if(!localStorage.getItem('ordercount') && !localStorage.getItem('count'))
    //     {
    //       localStorage.setItem("ordercount" , '0');
    //     }
    //     else if(localStorage.getItem('count') && localStorage.getItem('count') < this.count.toString())
    //     {
    //       var num = Number(localStorage.getItem('ordercount')) + 1
    //       localStorage.setItem("ordercount" , num.toString());
    //       window.location.reload();
    //       alert("done")
    //     }
    //     localStorage.setItem('count' , this.count.toString());
    // } , 
    // err=>{
    //   console.log("error")

    // })

   // localStorage.setItem('count' , this.count.toString());
    if (this.config.layout.sidebar.backgroundColor === 'white') {
      //this.logoUrl = 'assets/img/logo-dark.png';
      this.logoUrl = 'assets/img/logo2.png';

    }
    else {
      this.logoUrl = 'assets/img/logo2.png';
    }



    this.count = this.config.layout.sidebar.count;

  }

  ngAfterViewInit() {



    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
          this.renderer.addClass(this.logosidbar.nativeElement,'logodisplay')
          
        }
      }
    }, 0);


  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }

  handleicon()
  {
    let width = document.getElementById('sidebar-header').offsetWidth;
    console.log("header width" + width)
  }
  handleicon2()
  {
    document.getElementById('logo').style.width='100px'
    document.getElementById('logo').style.height='100px'

  }
}
