import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';


@Injectable()
export class AuthService {
  apiurl='https://api.buy2go.us/api';
  token 
  login= false;
  showlayout:boolean = false
  constructor(private _HttpClient:HttpClient,private router:Router,
    ) {
  
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signin(data) {
    return this._HttpClient.post(`${this.apiurl}/login`,data)
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {   
    this.login = false;
    localStorage.setItem('a_token','')
    localStorage.setItem('login','')
    this.token = null;
  }

  getToken() {    
    this.token = localStorage.getItem('a_token')
    return this.token;
  }

  islogin()
  {
    this.login = true;
  }

  islogout()
  {
    
    //return this.login ? false : true
    //return this.token != null ? true : false
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    
   return this.token ? true: false
    
  }

  checkRole()
  {
    if( !localStorage.getItem('a_token'))
    {
      this.showlayout= false
      this.router.navigate(['/login'])
    }
    else if(localStorage.getItem('a_token') && localStorage.getItem('role') == 'backoffice')
    {
      this.showlayout= false
      this.router.navigate(['/login'])
    }
    else if(localStorage.getItem('a_token') && localStorage.getItem('role') == 'admin')
    {
      this.showlayout = true
    }
    return this.showlayout
  }
}
