import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { ProductsService } from './../../../shared/services/products.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'app/pages/full-layout-page/full-layout-page.component';
import { products } from 'app/pages/full-layout-page/full-layout-page model';


import { categories } from './../../categories/category model';
import { ToastrService } from 'ngx-toastr';



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {

  value = '';
  matcher = new MyErrorStateMatcher();
  public editProduct: FormGroup;
  minDate: Date;
  maxDate: Date;
  fileToUpload: File = null;
  formData = new FormData();
  updateid;
  Categories: any[] = []
  productId;
  productdetails: products = new products();
  imgshow = true;
  showForm = false;
  categoryId;
  categoryDetails: categories = new categories();
  selectedTags;
  tags=[];

  subCategories : any[] = []

  constructor(
    private fg: FormBuilder,
    private _ProductsService: ProductsService,
    private router: Router,
    private _ToastrService: ToastrService,
    public dialogRef: MatDialogRef<EditItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
  }


  ngOnInit() {
    setTimeout(() => {
      this.getproductdetails()
    }, 1000);
    // this.formEditBuilder();
    this.getAllcatgory();


  }
  getAllcatgory() {
    this._ProductsService.getAllCatgory().subscribe(
      res => {
        this.Categories = res['data'] as categories[];
        this.Categories.forEach((element,index) => {
          this.subCategories.push(...element.sub_categories)
        });
        console.log(this.subCategories)
      },
      err => {
        console.log("error")
      })
  }

  getTags()
  {
    this._ProductsService.getTags().subscribe((res) => {
      if(res['status'] == true)
      {
        var data = res['data']['popular'];
        for (let item in data) {
          this.tags.push({name:data[item]});
          this.formEditBuilder();
          this.showForm = true;
        
        }
 
       
      }
    })

  }

  // get product details
  getproductdetails() {

    this._ProductsService.new_productId.subscribe(value => {
      this.productId  = value;
      this._ProductsService.getproductdetials(this.productId).subscribe(
        res => {
          this.productdetails = res['data'];
          this.getcategory();
          console.log(res['data']['tags']);
          this.selectedTags = res['data']['tags']
          this.getTags();
        
  
        },
        err => {
          console.log('error')
        }
      )
    });
 
  }

  loadimage() {
    this.imgshow = false
  }

  getcategory() {
    this.categoryId = this.productdetails.category_id;
    this._ProductsService.getcategory(this.categoryId).subscribe(
      res => {
        this.categoryDetails = res['data'];
        this.formEditBuilder();
      },
      err => { console.log("error") })
  }

  hideproduct() {
    this.editProduct.controls['hide'].setValue('1')
    this.postformdata();

  }
  showproduct() {
    this.editProduct.controls['hide'].setValue('0')
    this.postformdata();

  }


  setcategoryid(selectchoose) {
    let i = 0;
    for (var k in this.subCategories) {
      if (this.subCategories[i].name == selectchoose.value) {
        this.updateid = this.subCategories[i].id;
      }
      i = i + 1;

    }
    this.editProduct.controls['category_id'].setValue(this.updateid)
  }

  postMethod(files: FileList) {
    this.fileToUpload = <File>files[files.length-1];
    this.formData.append('image', this.fileToUpload, this.fileToUpload.name);
  }

  postformdata() {
    this.formData.append('sku_number', this.editProduct.controls['sku_number'].value);
    this.formData.append('description', this.editProduct.controls['description'].value);
    this.formData.append('profit', this.editProduct.controls['profit'].value  == null ? 0: this.editProduct.controls['profit'].value);
    this.formData.append('sold_quantity', this.editProduct.controls['sold_quantity'].value  == null ? 0: this.editProduct.controls['sold_quantity'].value);
    this.formData.append('category_id', this.editProduct.controls['category_id'].value);
    this.formData.append('created_at', this.editProduct.controls['created_at'].value);
    this.formData.append('cost_item', this.editProduct.controls['cost_item'].value == null ? 0: this.editProduct.controls['cost_item'].value);
    this.formData.append('sell_item', this.editProduct.controls['sell_item'].value);
    this.formData.append('total_sell', this.editProduct.controls['total_sell'].value == null ? 0: this.editProduct.controls['total_sell'].value);
    this.formData.append('cost_two_items', this.editProduct.controls['cost_two_items'].value);
    this.formData.append('property', this.editProduct.controls['property'].value  == null ? 0: this.editProduct.controls['property'].value);
    this.formData.append('hide', this.editProduct.controls['hide'].value);
    this.formData.append('adult', this.editProduct.controls['adult'].value);
    this.formData.append('is_packaged', this.editProduct.controls['is_packaged'].value);
    this.formData.append('size', this.editProduct.controls['size'].value  == null ? 0: this.editProduct.controls['size'].value);
    this.formData.append('food', this.editProduct.controls['food'].value  == null ? 0: this.editProduct.controls['food'].value);
    this.formData.append('details', this.editProduct.controls['details'].value); 
    this.formData.append('tags', this.selectedTags  == null ? 0: this.selectedTags); 

  }

  onSubmit() {

    /* if(this.editProduct.invalid)
     {
       //alert("invaild")
       //this.formData = new FormData();
       return;
     }*/
    //this.formEditBuilder();
    this._ProductsService.update(this.formData, this.productId).subscribe(
      res => {
        this.formData = new FormData();
        this.onNoClick();
        this._ToastrService.success('product updated successfuly', 'Success', { timeOut: 3000, progressBar: true, closeButton: true });

      },
      err => {
        alert(JSON.stringify(err['error'].error))
        this.formData = new FormData();
      }
    )
  }
  formEditBuilder() {
    this.editProduct = this.fg.group({
      sku_number: [this.get(this.productdetails, 'sku_number', ''), Validators.required],
      description: [this.get(this.productdetails, 'description', ''), Validators.required],
      created_at: [this.get(this.productdetails, 'created_at', '')],
      profit: [this.get(this.productdetails, 'profit', '')],
      sold_quantity: [this.get(this.productdetails, 'sold_quantity', '')],
      category_name: [this.get(this.categoryDetails, 'name', ''), Validators.required],
      category_id: [this.get(this.productdetails, 'category_id', ''), Validators.required],
      quantity: [this.get(this.productdetails, 'quantity', '')],
      image: ['', Validators.required],
      cost_item: [this.get(this.productdetails, 'cost_item', '')],
      cost_two_items: [this.get(this.productdetails, 'cost_two_items', '')],
      sell_item: [this.get(this.productdetails, 'sell_item', ''), Validators.required],
      total_sell: [this.get(this.productdetails, 'total_sell', '')],
      property: [this.get(this.productdetails, 'property', '')],
      food :[this.get(this.productdetails, 'food', '')],
      hide: [''],
      adult: [this.get(this.productdetails, 'adult', '')],
      is_packaged:[this.get(this.productdetails, 'is_packaged', '')],
      size:[this.get(this.productdetails, 'size', '')],
      details:[this.get(this.productdetails, 'details', '')],
      tags:[]





    });


  }
  clearformvalue() {
    this.editProduct.reset();
    this.formData = new FormData();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handlechange(event) {
    let prop = 'featured'
    if (event.checked) {
      this.editProduct.controls['property'].setValue(prop)
    }
    else {
      this.editProduct.controls['property'].setValue('')
    }
  }

  handlechangefood(event)
  {
    if (event.checked) {
      this.editProduct.controls['food'].setValue('food')
    }
  }
  handlechangeadult(event)
  {
    if (event.checked) {
      this.editProduct.controls['adult'].setValue('1')
    }
    else {
      this.editProduct.controls['adult'].setValue('0')
    }

  }

  handlechangepackaged(event)
  {
    if (event.checked) {
      this.editProduct.controls['is_packaged'].setValue('1')
    }
    else {
      this.editProduct.controls['is_packaged'].setValue('0')
    }

  }

  get(object: any, key: string, $default: any = null): any {
    try {
      let value = key
        .split(".")
        .reduce((obj, property) => obj[property], object);

      return undefined === value ? $default : value;
    } catch (err) {
      return $default;
    }
  }



}
