
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {MaterialModule} from './material/material.module'
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {ItemsModule} from './pages/items/items.module';

import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import {MessagingService} from "./shared/services/messaging.service"
import { environment } from "../environments/environment";
import { AsyncPipe } from "../../node_modules/@angular/common";

import { 
    PerfectScrollbarModule, 
    PERFECT_SCROLLBAR_CONFIG, 
    PerfectScrollbarConfigInterface
  } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginAdminComponent } from './login-admin/login-admin/login-admin.component';
import { AddItemComponent } from './pages/items/add-item/add-item.component';
import { AddStoreComponent } from './pages/stores/add-store/add-store.component';
import { CommonModule } from '@angular/common';
import { OrdersListComponent } from './pages/orders/orders-list/orders-list.component';
import { EditItemComponent } from './pages/items/edit-item/edit-item.component';
import { ToastrModule } from 'ngx-toastr';
import { DriverDetailsComponent } from './pages/drivers/driver-details/driver-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { OrderReceiptComponent } from './pages/order-receipt/order-receipt.component';
import { FullImageComponent } from './pages/order-receipt/full-image/full-image.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };
  
  export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }


  @NgModule({
    declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, DriverDetailsComponent, OrderReceiptComponent, FullImageComponent],
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      MaterialModule,
      MatTabsModule,
      SharedModule,
      FormsModule,
      ItemsModule,
      ReactiveFormsModule,
      HttpClientModule,
      AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
      AngularFireModule.initializeApp(environment.firebase),
      NgbModule.forRoot(),
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      PerfectScrollbarModule
    ],
    providers: [
      MessagingService,
      AsyncPipe,
      AuthService,
      AuthGuard,
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent],
    entryComponents:[AddItemComponent,EditItemComponent, FullImageComponent]



  })
  export class AppModule {}
  