import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  

  apiurl='https://api.buy2go.us/api';
  productId;
  categoryId;      
  token = localStorage.getItem('a_token')
  headeroption = {
    headers : new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    })
  };

  new_productId: BehaviorSubject<number>;
  new_categoryId: BehaviorSubject<number>;

  constructor(private _HttpClient:HttpClient) {
    this.new_productId = new BehaviorSubject(this.productId);
    this.new_categoryId = new BehaviorSubject(this.categoryId);

   }

  // get all products
  getAll()
  {

     return this._HttpClient.get(`${this.apiurl}/products?admin=1`,this.headeroption)

  };
  
  // get product info 
  getproducts()
  {
    return this._HttpClient.get(`${this.apiurl}/product/info`,this.headeroption)
  }

  // add product
  add(data)
  {
    return this._HttpClient.post(`${this.apiurl}/products`,data,this.headeroption)
  }

  // delete product
  delete(id:number)
  {
    return this._HttpClient.get(`${this.apiurl}/prods/${id}/remove`,this.headeroption)
  }

  // get product of category
  getcategoryproduct(id)
  {
    return this._HttpClient.get(`${this.apiurl}/products?category_id=${id}`)
  }

  // get product by id 
  getproductdetials(id)
  {
    return this._HttpClient.get(`${this.apiurl}/products/${id}`)
  }

  // update product
  update(data,id)
  {
    return this._HttpClient.post(`${this.apiurl}/products/${id}`,data,this.headeroption)
  }

  setproductId(id)
  {
    this.productId = id;
    this.new_productId.next(this.productId);
  }






                   // category service

  // get all catgories
  getAllCatgory()
  {
    return this._HttpClient.get(`${this.apiurl}/categories?admin=1`)
  }

  // get category by id
  getcategory(id)
  {
    return this._HttpClient.get(`${this.apiurl}/categories/${id}`)
  }

  // add new category

  addcategory(data)
  {
    return this._HttpClient.post(`${this.apiurl}/categories`,data,this.headeroption)
  }

  //delete category
  deletecategory(id)
  {
    return this._HttpClient.delete(`${this.apiurl}/categories/${id}`,this.headeroption)
  }

    // update category
    updatecategory(data,id)
    {
      return this._HttpClient.post(`${this.apiurl}/categories/${id}`,data,this.headeroption)
    }


  //get tags
  getTags()
  {
    return this._HttpClient.get('https://api.buy2go.us/api/tags/list',this.headeroption)
  }


// set id category
  setcategoryId(id)
  {
    this.categoryId = id;
    this.new_categoryId.next(this.categoryId);
  }

}
