import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    //my route 
    {
        path: '/admin/dashboard', title: 'Dashboard', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },

    {
        path: '/admin/support', title: 'Support', icon: 'ft-phone', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },

    // {
    //     path: '', title: 'Reports', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    //     submenu: [
    //         { path: '/admin/reports/customer', title: 'Customers', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //         { path: '/admin/reports/item', title: 'Items ', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //         { path: '/admin/reports/sales', title: 'Sales', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //         { path: '/admin/reports/orders', title: 'Orders ', icon: 'ft-tablet', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //        // { path: '/admin/reports/stores', title: 'Stores ', icon: 'ft-aperture', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },

    //     ]
    // },

    
  

    {
        path: '', title: 'administration', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/admin/products', title: 'Products', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/stores', title: 'Stores', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/categories', title: 'Categories', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/subcategories', title: 'Sub Categories', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/users', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/drivers', title: 'drivers', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/sliders', title: 'Sliders', icon: 'ft-square', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            // { path: '/admin/refunds', title: 'Refund Orders', icon: 'ft-tablet', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/promoCode', title: 'promo Code', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/categorydeals', title: 'Category Deals', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/issues', title: 'Issues', icon: 'ft-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/areas', title: 'Areas', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
    },

    {
        path: '', title: 'Policy', icon: 'ft-command', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/admin/faq', title: 'FAQ', icon: 'ft-edit-2', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/tos', title: 'Terms', icon: 'ft-help-circle', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/instruction', title: 'Instructions', icon: 'ft-alert-triangle', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/admin/setting', title: 'Settings', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },

        ]
    },

    // {
    //     path: '/admin/orders', title: 'Orders', icon: 'ft-tablet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },

    {
        path: '', title: 'Orders', icon: 'ft-tablet', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {path: '/admin/orders', title: 'All Orders', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: []},
            {path: '/admin/Orders/New', title: 'New', icon: 'ft-tablet', class: '', badge: `${localStorage.getItem('ordercount')}`, badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            {path: '/admin/Orders/Inprogress', title: 'In progress', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            // {path: '/admin/Orders/Ready', title: 'Ready', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            {path: '/admin/Orders/Onway', title: 'Onway', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            {path: '/admin/Orders/Delivered', title: 'Delivered', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            // {path: '/admin/Orders/Uncompleted', title: 'Uncompleted', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },
            // {path: '/admin/Orders/Cancelled', title: 'Cancelled', icon: 'ft-tablet', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [] },

        ]
    },

];
