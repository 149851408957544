import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from "@angular/forms";
import {ErrorStateMatcher} from '@angular/material/core';
import { Router } from '@angular/router';
import {ProductsService} from './../../../shared/services/products.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from 'app/pages/full-layout-page/full-layout-page.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { ToastrService } from 'ngx-toastr';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ElementRef, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

interface Category {
  id: number;
  name: string;
}


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {

  value = '';
  matcher = new MyErrorStateMatcher();
  public createNewProduct: FormGroup;
  minDate: Date;
  maxDate: Date;
  fileToUpload: File = null;
  formData = new FormData();
  Categories: any[]=[];
  updateid;
  valuecheck;
  categoryName ="";
  tags=[];
  showLayout = false;
  selectedTags;

  subCategories : any[] = []

  


  constructor( 
    private fg: FormBuilder,
    private _ProductsService: ProductsService,
    private _ToastrService: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<AddItemComponent>,
    private modalService: NgbModal,

    @Inject(MAT_DIALOG_DATA) public data: DialogData)
   {
        // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 20, 0, 1);
        this.maxDate = new Date(currentYear + 1, 11, 31);



     
   }


 

  ngOnInit() {
    this.getAllcatgory();
    this.getTags()


    // setTimeout(() => {
    //   this.getTags()

    // }, 2000);



  }
  getTags()
  {
    this._ProductsService.getTags().subscribe((res) => {
      if(res['status'] == true)
      {
        var data = res['data']['popular'];
        for (let item in data) {
          this.tags.push({name:data[item]});
          this.formCreatBuilder();
          this.showLayout = true

        
        }
 
       
      }
    })

  }
  getAllcatgory()
  {
    this._ProductsService.getAllCatgory().subscribe(
      res=>{
        this.subCategories = [] 
        this.Categories = res['data'] as any[];
        this.Categories.forEach((element,index) => {
          this.subCategories.push(...element.sub_categories)
        });
        console.log(this.subCategories)
      },
      err=>
      {
             console.log("error")
      })
  }
  setcategoryid(selectchoose) {
  //  this.categoryName = selectchoose.value;
  console.log(selectchoose.value);
  if(selectchoose.value =="ALCOHOL" || selectchoose.value =="TOBACCO")
  {
    //alert("yes")
    document.getElementById("checkeadult").style.display='block'
  }
  else{
    document.getElementById("checkeadult").style.display='none'


  }
    let i = 0;
    for (let k of this.subCategories) {
      if (k.name == selectchoose.value) {
        this.updateid = this.subCategories[i].id;
      }
      i = i + 1;

    }
    this.createNewProduct.controls['category_id'].setValue(this.updateid)
  }

  postMethod(files: FileList) {
    this.fileToUpload = <File>files[files.length-1];
    this.formData.append('image', this.fileToUpload, this.fileToUpload.name);
  }

  postformdata() {
    console.log('postformdata')
    this.formData.append('sku_number', this.createNewProduct.controls['sku_number'].value);
    this.formData.append('description', this.createNewProduct.controls['description'].value);
    this.formData.append('profit', this.createNewProduct.controls['profit'].value);
    this.formData.append('sold_quantity', this.createNewProduct.controls['sold_quantity'].value);
    this.formData.append('quantity', this.createNewProduct.controls['quantity'].value);
    this.formData.append('category_id', this.createNewProduct.controls['category_id'].value);
    this.formData.append('created_at', this.createNewProduct.controls['created_at'].value);
    this.formData.append('cost_item', this.createNewProduct.controls['cost_item'].value);
    this.formData.append('sell_item', this.createNewProduct.controls['sell_item'].value);
    this.formData.append('total_sell', this.createNewProduct.controls['total_sell'].value);
    this.formData.append('cost_two_items', this.createNewProduct.controls['cost_two_items'].value);
    this.formData.append('property', this.createNewProduct.controls['property'].value);
    this.formData.append('adult', this.createNewProduct.controls['adult'].value);
    this.formData.append('is_packaged', this.createNewProduct.controls['is_packaged'].value);
    this.formData.append('size', this.createNewProduct.controls['size'].value);
    this.formData.append('food', this.createNewProduct.controls['food'].value);
    this.formData.append('details', this.createNewProduct.controls['details'].value);
    this.formData.append('tags', this.selectedTags);
  }

  onSubmit() {
    console.log('this.createNewProduct.invalid', this.createNewProduct)
    if(this.createNewProduct.invalid)
    {
      return;
    }
    this._ProductsService.add(this.formData).subscribe(
      res => {
        this.onNoClick();     
        this._ToastrService.success('product added successfuly!', 'Success', { timeOut: 3000, progressBar: true, closeButton: true });
      },
      err => {
        this.clearformvalue();
        this._ToastrService.error(err['error'].error)
       // alert(JSON.stringify(err['error'].error))
        //this.formData = new FormData();
      }

    )

  }

 

  openmodel(model) {
    this.modalService.open(model).result.then(
      result => {
      },
      reson => { console.log(reson) }
    );
  }

  checkAdult()
  {
    this.createNewProduct.controls['adult'].setValue('1')
  }

  notCheckAduilt()
  {
    this.createNewProduct.controls['adult'].setValue('0')

  }

  formCreatBuilder()
  {
    this.createNewProduct = this.fg.group({
      sku_number: ['', Validators.required],
      description: ['',Validators.required],
      created_at: [''],
      profit:['1'],
      sold_quantity:['1'],
      category_name:['',Validators.required],
      category_id:[this.updateid,Validators.required],
      property:[''],
      quantity:[''],
      food:[''],
      size:[''],
      image:[''],
      cost_item:['1'],
      cost_two_items:[''],
      sell_item:['',Validators.required],
      total_sell:['1'],
      adult :['0'],
      is_packaged:['0'],
      details:[''],
      tags:['']
    });

  }
  clearformvalue()
  {
    this.formData.delete('sku_number')
    this.formData.delete('description');
    this.formData.delete('profit');
    this.formData.delete('sold_quantity');
    this.formData.delete('quantity');
    this.formData.delete('category_id');
    this.formData.delete('created_at');
    this.formData.delete('cost_item');
    this.formData.delete('sell_item');
    this.formData.delete('total_sell');
    this.formData.delete('cost_two_items');
    this.formData.delete('property');
    this.formData.delete('adult');
    this.formData.delete('is_packaged');
    this.formData.delete('size');
    this.formData.delete('food');
    this.formData.delete('details');
    this.formData.delete('tags');
    // this.createNewProduct.reset();
    // this.formData = new FormData();

  }

  handlechange(event)
  {
    if(event.checked)
    {
      this.valuecheck = 'featured'
      this.createNewProduct.controls['property'].setValue(this.valuecheck)
    }
  }
  handlechangeprepration(event)
  {
    if(event.checked)
    {
      this.createNewProduct.controls['food'].setValue('food')
    }

  }

  handlechangepackage(event)
  {
    if(event.checked)
    {
      this.createNewProduct.controls['is_packaged'].setValue('1')
    }
    else
    {
      this.createNewProduct.controls['is_packaged'].setValue('0')

    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
 
}
