import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from  '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from 'app/shared/services/orders.service';
import { OrderFile, orders } from '../orders/orders model';
import { FullImageComponent } from './full-image/full-image.component';

@Component({
  selector: 'app-order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.scss']
})
export class OrderReceiptComponent implements OnInit {
  order: orders;
  orderId: string;
  receipts: OrderFile[];
  deliveryPhotos: OrderFile[];
  totalReceiptAmount: number;
  constructor(private route: ActivatedRoute, private _OrdersService: OrdersService, private _MatDialog: MatDialog) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.queryParamMap.get('orderId');
    this.getOrder();
  }

  getOrder() {
    this._OrdersService.getOrderDetails(this.orderId).subscribe(
      (res) => {
        this.order = res["data"] as orders;
        this.totalReceiptAmount = this.order.total_paid_by_driver;
        this.receipts = this.order.files.filter(file => file.type === 'invoice');
        this.deliveryPhotos = this.order.files.filter(file => file.type === 'delivered');
        console.log(this.receipts,  this.deliveryPhotos)
      },
      (err) => {
        console.log("error", err);
      }
    );
  }

  openImageDialog(src) {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width='50%';
    dialogConfig.height='80vh';
    dialogConfig.data = {imageSrc : src}
    this._MatDialog.open(FullImageComponent,dialogConfig);
  }

}
