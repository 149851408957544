import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DriversService } from 'app/shared/services/drivers.service';
import { driver } from '../drivers model';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {
  driverId: string;
  driver: driver;
  dataSource: any;
  displayedColumns: string[] = [
    "order_number",
    "customer_name",
    "Status",
    "cost",
    "tips",
    "date",
    "issue",
    "customer_address",
    "more-details"
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private route: ActivatedRoute, private _driversService: DriversService, private router: Router) { }

  ngOnInit() {
    this.driverId = this.route.snapshot.queryParamMap.get('driverId');
    console.log('this.driverid', this.driverId)
    this.getDriver();
  }

  getDriver()
  {
    this._driversService.getDriver(this.driverId).subscribe(
      res=>{
        this.driver = res['data'] as driver;
        console.log(this.driver);
        this.driver.orders.sort((a,b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.delivered_at).getTime() - new Date(a.delivered_at).getTime();
        });
        this.dataSource = new MatTableDataSource(this.driver.orders);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      err=>
      {
        console.log("error")
      })
  }

  showOrderReceipt(id) {
    this.router.navigate(['/admin/order-receipt'], {queryParams:  {orderId: id}});
  }
}
