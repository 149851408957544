import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemsRoutingModule } from './items-routing.module';
import { AddItemComponent } from './add-item/add-item.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from './../../material/material.module';
import { EditItemComponent } from './edit-item/edit-item.component';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import {MatChipsModule} from '@angular/material/chips';



@NgModule({
  declarations: [AddItemComponent, EditItemComponent],
  imports: [
    CommonModule,
    ItemsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatChipsModule
  ],

})
export class ItemsModule { }
