import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service'
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { HttpHeaders } from '@angular/common/http';
import { EmployeesService } from '../services/employees.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = "en";
  userName;
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};

  constructor(public translate: TranslateService,
    private route: Router,
    private _AuthService: AuthService,
    private _EmployeesService : EmployeesService,

    private layoutService: LayoutService, private configService: ConfigService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.userName = localStorage.getItem('name')
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logout() {
    let id = localStorage.getItem('id');
    let token = localStorage.getItem('a_token');
    var headeroption = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };
    
    this._EmployeesService.activeEmployee(id, 0, headeroption).subscribe(
      res => { console.log(res) },
      err => { 'error' })

    localStorage.setItem('login', '')
    localStorage.removeItem('a_token')
    localStorage.clear();
    window.location.reload();

    this.route.navigate(['/login'])
  }
}
