import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  
  apiurl='https://api.buy2go.us/api';
  token = localStorage.getItem('a_token');

  headeroption = {
    headers : new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    })
  };
  constructor(private _HttpClient:HttpClient) {
   }


    // get all employees
    getemployees()
    {
      console.log("tok" ,this.token)

      return this._HttpClient.get(`${this.apiurl}/users/activity/all`,this.headeroption)
  
    }

    // post active employee
    activeEmployee(id,value,token)
    {
      return this._HttpClient.get(`${this.apiurl}/emp/${id}/set?is_online=${value}`,token)
    }
}
