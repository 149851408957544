import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  notificationToken = new BehaviorSubject(null);


  constructor(private angularFireMessaging: AngularFireMessaging) {

    this.angularFireMessaging.messaging.subscribe(
      (msgings: any) => {
        msgings.onMessage = msgings.onMessage.bind(msgings);
        msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
      });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("token", token);
        localStorage.setItem('notificationAdmin_token', token);
        this.notificationToken.next(token)
      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
        this.currentMessage.next(msg);
        console.log("message" , this.currentMessage);
        console.log("call api")
      });
  }
}
