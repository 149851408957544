import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  /*transform(value: any, args?: any): any {
    console.log(args + ' ' + value);
    if (args == null) {
    return null;
    }
    return value.filter((item) => item.user.name.includes(args));
  }*/

  transform(items: any, a: string, b: string){
    if (items && items.length){
        return items.filter(item =>{
            if (a&& item.user.name.toLowerCase().indexOf(a.toLowerCase()) === -1){
                return false;
            }
            if (b && item.store.name.toLowerCase().indexOf(b.toLowerCase()) === -1){
                return false;
            }
            return true;
       })
    }
    else{
        return items;
    }
  }

}
