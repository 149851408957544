import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from 'app/layouts/full/full-layout.component';
import { DashbordPageComponent } from 'app/pages/dashbord/dashbord-page/dashbord-page.component';
import { DriverDetailsComponent } from 'app/pages/drivers/driver-details/driver-details.component';
import { OrderReceiptComponent } from 'app/pages/order-receipt/order-receipt.component';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [

  //my route
  {
    path: '',
    loadChildren: () => import('../../pages/dashbord/dashbord.module').then(m => m.DashbordModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'products',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('../../pages/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'subcategories',
    loadChildren: () => import('../../pages/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'sliders',
    loadChildren: () => import('../../pages/sliders/sliders.module').then(m => m.SlidersModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../pages/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'Orders/:type',
    loadChildren: () => import('../../pages/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('../../pages/stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: 'promoCode',
    loadChildren: () => import('../../pages/promo-code/promo-code.module').then(m => m.PromoCodeModule)
  },
  {
    path: 'drivers',
    loadChildren: () => import('../../pages/drivers/drivers.module').then(m => m.DriversModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../../pages/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'tos',
    loadChildren: () => import('../../pages/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('../../pages/policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'time',
    loadChildren: () => import('../../pages/time/time.module').then(m => m.TimeModule)
  },
  {
    path: 'instruction',
    loadChildren: () => import('../../pages/instructions/instructions.module').then(m => m.InstructionsModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'employees',
    loadChildren: () => import('../../pages/employees/employees.module').then(m => m.EmployeesModule)
  },
  {
    path: 'refunds',
    loadChildren: () => import('../../pages/refund-orders/refund-orders.module').then(m => m.RefundOrdersModule)
  }
,
  {
    path: 'categorydeals',
    loadChildren: () => import('../../pages/category-deals/category-deals.module').then(m => m.CategoryDealsModule)
  },
  {
    path: 'issues',
    loadChildren: () => import('../../pages/issues/issues.module').then(m => m.IssuesModule)
  }
  , {
    path: 'areas',
    loadChildren: () => import('../../pages/areas/areas.module').then(m => m.AreasModule)
  },
  {
    path: 'driver-details', 
    component: DriverDetailsComponent
  }
  ,
  {
    path: 'order-receipt', 
    component:  OrderReceiptComponent
  },

];
