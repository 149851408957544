import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatPaginatorModule,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatToolbarModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatButtonToggleModule
  
  


} from '@angular/material';

const MaterialsComponents =[
  MatButtonModule,
  MatAutocompleteModule,
  MatTableModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule ,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatToolbarModule,
  MatGridListModule,
  MatToolbarModule,
  MatGridListModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatButtonToggleModule
  
];

@NgModule({
  declarations: [],
  imports: [MaterialsComponents],
  exports:[MaterialsComponents]
})
export class MaterialModule { }
