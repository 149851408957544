
export class Categories {
    id?:number;
    name?:string;
    hide?:string;
  }

export class products
{
    id?:number;
    sku_number?:number;
    description?:string;
    image?:string;
    category_id?:number;
    total_sold?:number;
    cost_item?: number;
    sell_item?: number;
    total_sell?: number;
    profit?: number;
    sold_quantity?: number;
    quantity?: number;
    created_at?: string;
    details? : string;
    hide? :any;
    category?:Categories
}