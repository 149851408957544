import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  orderId;
  userId;
  cost;
  statusList;
  orderItemId;
  orderItemQuantity;
  orderAvailable_quantity
  orderStatus;
  orderDate;
  orderAccept;
  orderPaid;
  storeName;
  payment_method;
  taxes;
  period;

  token = localStorage.getItem('a_token');
  apiurl='https://api.buy2go.us/api';

  headeroption = {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    })
  };

  new_orderId: BehaviorSubject<number>;
  new_cost: BehaviorSubject<number>;

  new_orderItemId: BehaviorSubject<number>;


  constructor(private _HttpClient: HttpClient) {
    this.new_orderId = new BehaviorSubject(this.orderId)
    this.new_cost = new BehaviorSubject(this.cost)

    this.new_orderItemId = new BehaviorSubject(this.orderItemId)

  }

  // get all orders
  getAll() {
    return this._HttpClient.get(`${this.apiurl}/store/orders`, this.headeroption)

  };

  // filter all orders by status
  getorderstatus(status) {
    return this._HttpClient.get(`${this.apiurl}/store/orders?status=${status}`, this.headeroption)

  };

  // filter all orders by all
  getFilterorders(status, store, age) {
    return this._HttpClient.get(`${this.apiurl}/store/orders?status=${status}&store=${store}&age_verification=${age}`, this.headeroption)
  }

  // get order items
  getOrderItems(id) {
    return this._HttpClient.get(`${this.apiurl}/orders/${id}/orderitems`, this.headeroption)
  };

  // send list order
  send(id, number) {
    return this._HttpClient.post(`${this.apiurl}/items/${id}/exist `, number, this.headeroption)

  };
  // set order details
  setorderdetails(row) {

    this.orderId = row.id;
    this.cost = row.cost;
    this.orderStatus = row.status;
    this.orderDate = row.delivered_at;
    this.orderPaid = row.is_paid;
    this.storeName = row.store.name;
    this.userId = row.user_id;
    this.statusList = row.listed;
    this.payment_method = row.payment_method;
    this.taxes =row.tax;
    this.period =row.time;
    this.new_orderId.next(this.orderId);
    this.new_cost.next(this.cost)
  }

 

  // set orderitem details
  setOrderItemId(id, quantity, available_quantity) {
    this.orderItemId = id;
    this.orderItemQuantity = quantity;
    this.orderAvailable_quantity = available_quantity;
    this.new_orderItemId.next(this.orderItemId);

  }

  // set value of accept
  setCheckvalue(accept) {
    this.orderAccept = accept;
    console.log("service", this.orderAccept)
  }

  // update order
  updatestatus(id, data) {
    return this._HttpClient.post(`${this.apiurl}/store/orders/${id}/finished`, data, this.headeroption)
  }

  updatedelverystatus(id, data) {
    return this._HttpClient.post(`${this.apiurl}/store/orders/${id}/delivered`, data, this.headeroption)
  }

  updateinprogressstatus(id, status) {
    return this._HttpClient.post(`${this.apiurl}/orders/${id}/status`, status, this.headeroption)
  }

  // checkorder

  check(id, data) {
    return this._HttpClient.post(`${this.apiurl}/items/${id}/exist`, data, this.headeroption)

  };


  //update quantity of orderitem
  editquantity(id, data) {
    return this._HttpClient.post(`${this.apiurl}/edit/${id}/quantity`, data, this.headeroption)
  };

  printOrder(salesOrderID){
    window.location.href= this.apiurl + '/orders/' + salesOrderID + '/print';
   
  }


// count news orders
  ordercount() {
    return this._HttpClient.get(`${this.apiurl}/orders/new/count`, this.headeroption)
  };


  getOrderDetails(orderID) {
    return this._HttpClient.get(`${this.apiurl}/orders/${orderID}`, this.headeroption).pipe(
      map((res) => {
        return res;
      })
    );
  }

}
