import { Injectable } from '@angular/core';
import { TemplateConfig } from '../template-config/config.interface';
import {OrdersService} from '../services/orders.service'

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public templateConf: TemplateConfig;
    count: number = 0;


    constructor(private _OrdersService : OrdersService) 
    {
        this._OrdersService.ordercount().subscribe(
            res=>{
              console.log("res" , res['data']['count']);
              this.count = res['data']['count'];
              console.log("hh" , this.count)
      
              if(!localStorage.getItem('ordercount') && !localStorage.getItem('count'))
              {
                localStorage.setItem("ordercount" , '0');
              }
              else if(localStorage.getItem('count') && localStorage.getItem('count') < this.count.toString())
              {
                var num = Number(localStorage.getItem('ordercount')) + 1
                localStorage.setItem("ordercount" , num.toString());
                // alert("done")
                window.location.reload();

              }
              localStorage.setItem('count' , this.count.toString());
          } , 
          err=>{
            console.log("error")
      
          })
      
        this.setConfigValue();
    }

    setConfigValue() {
        this.templateConf = {
            layout: {
                variant: 'Light', // options:  Dark, Light & Transparent
                dir:'ltr', //Options: ltr, rtl
                sidebar: {
                    collapsed: false, //options: true, false
                    size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
                    backgroundColor: "man-of-steel", // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
                    backgroundImage: true, // Options: true, false | Set true to show background image
                    backgroundImageURL: 'assets/img/sidebar-bg/01.jpg',
                    count : this.count
                }
            }
        }
    }



}
